import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { login } from '../../actions/register';
import { Loading } from '../elements/Loading';
import '../styles/auth.css';
import { Row, Col } from 'react-bootstrap';

const ProposalLogin = () => {
	const [formData, setFormData] = useState({
		proposalSecret: '',
		proposalId: '',
		loading: false,
	});
	const { companyName, projectName } = useParams();
	const isAuthenticated = useSelector(state => state.register.isAuthenticated);
	const loading = useSelector(state => state.register.loading);
	const dispatch = useDispatch();
	const onChangeHandler = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		setFormData({ ...formData, loading: true });
		if (companyName && projectName) {
			return dispatch(login({ ...formData, companyName, projectName }));
		}
		dispatch(login({ ...formData }));
	};

	if (loading && formData.loading) {
		return <Loading />;
	}

	if (isAuthenticated) {
		return <Redirect to={`/home`} />;
	}

	return (
		<div className='container-fluid login'>
			<Row>
				<Col className='image-container' md={6} sm={12} xs={12}>
					<img className='proposal-image' src='/img/plane.svg' alt='' />
				</Col>
				<Col className='content-container' md={6} sm={12} xs={12}>
					<img className='logo-image' src='/img/logo.svg' alt='' />
					<h1> Welcome to SimpleM</h1>
					{projectName && companyName ? (
						<p>
							{`Enter the key to check Quotation for ${companyName}’s ${projectName}  `}{' '}
						</p>
					) : (
						<p></p>
					)}
					<Form className='form-container' onSubmit={onSubmit}>
						{!projectName && !companyName ? (
							<>
								<Form.Group controlId='formPassword'>
									<Form.Control
										className='row input-field'
										type='text'
										name='projectName'
										placeholder='Project Name'
										size='lg'
										required
										onChange={onChangeHandler}
									/>
								</Form.Group>
								<Form.Group controlId='formPassword'>
									<Form.Control
										className='row input-field'
										type='text'
										name='companyName'
										placeholder='Company Name'
										size='lg'
										required
										onChange={onChangeHandler}
									/>
								</Form.Group>
							</>
						) : (
							<></>
						)}
						<Form.Control
							className='row input-field'
							type='password'
							name='proposalSecret'
							placeholder='Proposal Secret'
							size='lg'
							required
							onChange={onChangeHandler}
						/>
						<button type='submit' className='row btn btn-outline-primary'>
							PROCEED
						</button>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default ProposalLogin;
