//Authentication request is done here, please refer to auth.js in reducer for handler logic

import axios from 'axios';
import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	USER_LOADED,
	LOAD_FAIL,
} from '../utils/consts';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
// TODO: Set some alerts using the alert action generator

export const login = ({
	proposalSecret,
	companyName,
	projectName,
}) => async dispatch => {
	try {
		const res = await axios.post(
			'/api/proposal/authenticate',
			{ proposalSecret, companyName, projectName },
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});
		dispatch(loadUser());
	} catch (err) {
		console.log(err);
		if (err.response && err.response.data && err.response.data.errors)
			dispatch(setAlert(err.response.data.errors.msg, 'danger'));
		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

export const loadUser = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	try {
		const res = await axios.get('/api/proposal/');
		dispatch({
			type: USER_LOADED,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: LOAD_FAIL,
		});
		console.log(err.message);
	}
};

export const logout = () => dispatch => {
	dispatch({ type: LOGOUT });
};
