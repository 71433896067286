import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProposalLogin from '../../components/AuthPages/ProposalLogin';

const PrivateRoute = ({ component, ...options }) => {
	const isLoading = useSelector(state => state.register.isLoading);
	const isAuthenticated = useSelector(state => state.register.isAuthenticated);
	let finalComponent =
		!isAuthenticated && !isLoading ? ProposalLogin : component;
	return <Route {...options} component={finalComponent} />;
};

export default PrivateRoute;
